<template>
  <div class="steps">
    <div class="steps__title">
      {{ $tm('onboarding.kyc.upload_type.title') }}
    </div>
    <div class="steps__subtitle">
      {{ $tm('onboarding.kyc.upload_type.subtitle') }}
      <a class="orange-link" target='_blank' href="https://www.fintrac-canafe.gc.ca/guidance-directives/client-clientele/Guide11/11-eng#s6:~:text=of%20the%20entity-,Annex%204%3A%20Examples%20of%20acceptable%20photo%20identification%20documents,-The%20following%20list">here.</a>
    </div>
    <div class="steps__investor mb-8" @click="next('upload')">
      <div class="flex items-center">
        <div
          class="
            steps__investor__icon steps__investor__icon--kyc-upload
            bg-gradient-green--desktop
          "
        >
          <img src="@/assets/ico/onboarding/ic_sharp-file-upload.svg" alt="" />
        </div>
        <div>
          <div class="font-bold">
            {{ $t('onboarding.kyc.upload_type.desktop') }}
          </div>
        </div>
      </div>
      <div>
        <img src="@/assets/svg/chev-right-2.svg" alt="" />
      </div>
    </div>
    <div class="opacity-60 cursor-not-allowed">
      <div class="steps__investor steps__investor--disabled mb-8" @click="''">
        <div class="flex items-center">
          <div
            class="
              steps__investor__icon steps__investor__icon--kyc-upload
              bg-gradient-green--desktop
            "
          >
            <img src="@/assets/ico/onboarding/cellphone-android.svg" alt="" />
          </div>
          <div>
            <div class="font-bold">
              {{ $t('onboarding.kyc.upload_type.mobile') }}
            </div>
          </div>
        </div>
        <div
          class="
            bg-orange-2
            rounded-lg
            font-semibold
            px-3
            py-1
            text-xs text-white
            absolute
            right-4
            top-4
          "
        >
          {{ $t('wording.coming_soon') }}
        </div>
        <!--div>
          <img src="@/assets/svg/chev-right-2.svg" alt="" />
        </div-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},
  emits: ['nextKyc'],
  setup(props, { emit }) {
    function next(value) {
      emit('nextKyc', value)
    }

    return {
      next
    }
  }
}
</script>
